import React from "react";

function Social() {
    return <>
        <a href="https://discord.gg/64XQQB874E" className="me-4">
            <img src="/img/discord.png" alt="Join Discord" className="img-fluid" style={{maxWidth: '3rem'}}/>
        </a>
        <a href="https://reddit.com/r/Junxions" className="me-4">
            <img src="/img/reddit.png" alt="Join Subreddit" className="img-fluid" style={{maxWidth: '3rem'}}/>
        </a>
        <a href="https://www.youtube.com/@CyberStormGamesOfficial" className="me-4">
            <img src="/img/youtube.png" alt="Subscribe on YouTube" className="img-fluid" style={{maxWidth: '3rem'}}/>
        </a>
        <a href="https://bsky.app/profile/cyberstormgames.bsky.social">
            <img src="/img/twitter.png" alt="Follow on Twitter" className="img-fluid" style={{maxWidth: '3rem'}}/>
        </a>
    </>
}

export default Social;